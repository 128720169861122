import { User } from '../../team/user/user.model';
import { Law } from '../law/law.model';
import { ITags } from './ITags';

export class Marker {
    constructor(
        public id: number,
        public colour: string,
        public law: Law,
        public user: User,
        public length: number,
        public modifiedHtml: string,
    ) { }

 public getBook() {

  }
}
