import { TaskStatus } from '../Status/TaskStatus.model';
import { User } from '../team/user/user.model';
import {Moment} from 'moment';

export class Rollbacktask {
  constructor(
    public goLiveId: number,
    public rollbackTaskGroup: string,
    public name: string,
    public microservice: string,
    public type: string,
    public status: TaskStatus,
    public responsible: User,
    public involvedUser: User[],
    public date: Date,
    public timeStart: string,
    public timeEnd: string,
    public timeCreated: Date,
    public description: string,
    public taskOrder: number,
    public id?: number,
    public open?: boolean,
  ) {}
}
