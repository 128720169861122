import { Law } from 'src/app/models/book/law/law.model';
import { Comment } from 'src/app/models/book/tags/comment';
import { Marker } from 'src/app/models/book/tags/marker';
import { Reference } from 'src/app/models/book/tags/reference';


export class DataObjectifier {

  // Take the data from Backend and make ts-Objects out of it
  public makeComments(data: any): Comment[] {
    if (!data) { return; }

    let value: Comment[];
    data.forEach(element => {
      value.push(this.objectifyComment(element));
    });


    return value;
  }



  objectifyComment(data: any): Comment {

    return new Comment(
      data.id,
      data.colour,
      data.position,
      data.law as Law,
      data.user,
      data.text,
      data.length,
    );
  }

  objectifyMarker(data: any): Marker {
    return new Marker(data.id, data.colour, data.law as Law, data.user, data.length, data.modifiedhtml);
  }

  objectifyReference(data: any): Reference {
    return;
  }

  // Maybe we need to make methods returning Arrays
}
