
export class User {
  constructor(
    public firstName: string,
    public lastName: string,
    public username: string,
    public password: string,
    public email: string,
    public avatar: string,
    public examMode?: boolean,
    public id?: number,
    public token?: string,
  ) {}

}
