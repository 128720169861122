import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PublicNavbarComponent } from './public-navbar/public-navbar.component';
import { PublicSidebarComponent } from './public-sidebar/public-sidebar.component';
import { SocketComponent } from './navbar/socket/socket.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule, NgbModule, FormsModule, MatSnackBarModule],
  declarations: [FooterComponent, NavbarComponent, SidebarComponent, PublicNavbarComponent, PublicSidebarComponent, SocketComponent],
  exports: [FooterComponent, NavbarComponent, SidebarComponent, PublicNavbarComponent, PublicSidebarComponent, SocketComponent]
})
export class ComponentsModule {}
