import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadInitControllerService} from '../../services/controller/load-init-controller.service';
import {NavigationEnd, Router} from '@angular/router';



@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  public sidebarColor = 'red';
  navigationSubscription;

  constructor(
    private loadInitControllerService: LoadInitControllerService,
    private router: Router,
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        //this.ngOnInit();
      }
    });
  }


  changeSidebarColor(color) {
    const sidebar = document.getElementsByClassName('sidebar')[0];
    const mainPanel = document.getElementsByClassName('main-panel')[0];

    this.sidebarColor = color;

    if (sidebar !== undefined) {
        sidebar.setAttribute('data', color);
    }
    if (mainPanel !== undefined) {
        mainPanel.setAttribute('data', color);
    }
  }
  changeDashboardColor(color) {
    const body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
        body.classList.add(color);
    } else if (body.classList.contains('white-content')) {
      body.classList.remove('white-content');
    }
  }

  ngOnInit() {

    if (sessionStorage.getItem('userid') != null) {
      this.loadInitControllerService.starLoadingDataWithoutUser();
    } else {
      this.loadInitControllerService.startLoadingData();
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
