import {EventEmitter, Injectable} from '@angular/core';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {TeamService} from '../data2/team.service';
import {UserService} from './user.service';
import {Law} from '../../models/book/law/law.model';
import { Comment } from 'src/app/models/book/tags/comment';
import { Reference } from 'src/app/models/book/tags/reference';

@Injectable({
  providedIn: 'root'
})
export class LawService {
  laws;
  comments;
  references;
  markers;
  httpOptionsWithToken;
  lawsChanged = new EventEmitter<any[]>();
  commentsChanged = new EventEmitter<any[]>();
  referencesChanged = new EventEmitter<any[]>();
  changeEvent = new EventEmitter<any>();
  snackBar: SnackbarComponent;



  constructor(
    private http: HttpClient,
    private router: Router,
    private teamService: TeamService,
    private userService: UserService,
    snackbarComp: SnackbarComponent
  ) {
    this.snackBar = snackbarComp;
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', Authorization: sessionStorage.getItem('bearerToken')
      })
    };
  }

// Kommentar, Markierung, Referenz API-Endpunkte alle hier rein
// immer load (Server), create und get Methoden (für andere components zugänglich machen) (manchmal auch update) -->


  // Paragraphen
  loadLaws() {
    this.http.get('/api/v1/laws', this.httpOptionsWithToken).subscribe(
      data => {
        this.laws = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Paragraphen konnten nicht geladen werden', 'Ok');
      },
      () => {
        this.lawsChanged.emit(this.laws);
        // this.userprofileService.loginUser(user);
        // this.snackBar.openSnackBar('Der Paragraph wurde erfolgreich geladen', 'Ok');
      },
    );
  }

  loadLawsOfBook(bookId: number) {
    let laws;
    this.http.get('/api/v1/laws/' + bookId, this.httpOptionsWithToken).subscribe(
      data => {
        laws = data;

        return data;
      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Paragraphen konnten nicht geladen werden', 'Ok');
      },
      () => {
        this.lawsChanged.emit();
        // this.lawsChanged.emit(this.laws);
        // this.userprofileService.loginUser(user);
        // this.snackBar.openSnackBar('Der Paragraph wurde erfolgreich geladen', 'Ok');
      },
    );
  }

  createLaw(law: Law) {
    const body = JSON.stringify(law);
    this.http.post('/api/v1/laws', body, this.httpOptionsWithToken).subscribe(
      data => {
        let law = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Die Paragraphen konnten leider nicht hinzugefügt werden. Überprüfe deine Daten', 'Ok');
      },
      () => {
        // this.userprofileService.loginUser(user);
        this.snackBar.openSnackBar('Sie haben die Paragraphen erfolgreich hinzugefügt', 'Ok');
      },
    );
  }

  public getLaws() {
    return this.laws;
  }

  public getLawsOfBook(bookId: number) {
    let lawsOfBook = [];
    for (const law of this.laws) {
      if (law.book.id === bookId) {
        return law;
      }
    }
    return null;
  }

  public getLaw(id: number) {
    for (const law of this.laws) {
      if (law.id === id) {
        return law;
      }
    }
    return null;
  }

  // Kommentare
  loadComments() {
    this.http.get('/api/v1/comments', this.httpOptionsWithToken).subscribe(
      data => {
        this.comments = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Kommentare konnten nicht geladen werden', 'Ok');
      },
      () => {
        this.commentsChanged.emit(this.comments);
        // this.userprofileService.loginUser(user);
        // this.snackBar.openSnackBar('Der Kommentar wurde erfolgreich geladen', 'Ok');
      },
    );
  }

  createLawComment(comment: Comment) {
    const body = JSON.stringify(comment);
    this.http.post('/api/v1/comments', body, this.httpOptionsWithToken).subscribe(
      data => {
        let comment = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Der Kommentar konnte leider nicht hinzugefügt werden. Überprüfe deine Daten', 'Ok');
      },
      () => {
        // this.userprofileService.loginUser(user);
        this.snackBar.openSnackBar('Sie haben den Kommentar erfolgreich hinzugefügt', 'Ok');
      },
    );
  }

  public getComments() {
    return this.comments;
  }

  public getComment(id: number) {
    for (const comment of this.comments) {
      if (comment.id === id) {
        return comment;
      }
    }
    return null;
  }

  // Referenzen
  loadReferences() {
    this.http.get('/api/v1/references', this.httpOptionsWithToken).subscribe(
      data => {
        this.references = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Verlinkungen konnten nicht geladen werden', 'Ok');
      },
      () => {
        this.referencesChanged.emit(this.references);
        // this.userprofileService.loginUser(user);
        // this.snackBar.openSnackBar('Die Verlinkung wurde erfolgreich geladen', 'Ok');
      },
    );
  }

  createLink(link: Reference) {
    const body = JSON.stringify(link);
    this.http.post('/api/v1/references', body, this.httpOptionsWithToken).subscribe(
      data => {
        let link = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Die Verlinkung konnte leider nicht hinzugefügt werden. Überprüfe deine Daten', 'Ok');
      },
      () => {
        // this.userprofileService.loginUser(user);
        this.snackBar.openSnackBar('Sie haben die Verlinkung erfolgreich hinzugefügt', 'Ok');
      },
    );
  }

  public getReferences() {
    return this.references;
  }

  public getLink(id: number) {
    for (const link of this.references) {
      if (link.id === id) {
        return link;
      }
    }
    return null;
  }


  // Markierungen -> muss noch verändert werden
  loadMarkers() {
    this.http.get('/api/v1/markers', this.httpOptionsWithToken).subscribe(
      data => {
        this.references = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Verlinkungen konnten nicht geladen werden', 'Ok');
      },
      () => {
        this.referencesChanged.emit(this.references);
        // this.userprofileService.loginUser(user);
        // this.snackBar.openSnackBar('Die Verlinkung wurde erfolgreich geladen', 'Ok');
      },
    );
  }

  createMarker(link: Reference) {
    const body = JSON.stringify(link);
    this.http.post('/api/v1/markers', body, this.httpOptionsWithToken).subscribe(
      data => {
        let link = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Die Verlinkung konnte leider nicht hinzugefügt werden. Überprüfe deine Daten', 'Ok');
      },
      () => {
        // this.userprofileService.loginUser(user);
        this.snackBar.openSnackBar('Sie haben die Verlinkung erfolgreich hinzugefügt', 'Ok');
      },
    );
  }

  public getMarkers() {
    return this.references;
  }

  public getMarker(id: number) {
    for (const link of this.references) {
      if (link.id === id) {
        return link;
      }
    }
    return null;
  }
}
