import {EventEmitter, Injectable} from '@angular/core';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {TeamService} from '../data2/team.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  parsers;
  parserChanged = new EventEmitter<any[]>();

  httpOptionsWithToken;
  snackBar: SnackbarComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private teamService: TeamService,
    private userService: UserService,
    snackbarComp: SnackbarComponent
  ) {
    this.snackBar = snackbarComp;
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: sessionStorage.getItem('bearerToken')
      })
    };
  }

  loadParsers() {
    this.http.get('/api/v1/parser', this.httpOptionsWithToken).subscribe(
      data => {
        this.parsers = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Parser konnten nicht geladen werden', 'Ok');
      },
      () => {


        this.parserChanged.emit(this.parsers);
      },
    );
  }

  public getParsers() {

    return this.parsers;
  }

  public getParser(id: number) {
    for (const parser of this.parsers) {
      if (parser.id === id) {
        return parser;
      }
    }
    return null;
  }
}
