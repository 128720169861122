import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginUserInterface } from '../../interfaces/login-user.interface';
import { RegisterUserInterface } from '../../interfaces/register-user.interface';
import { UserprofileService } from '../data2/userprofile.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Validators} from '@angular/forms';
import {LoginComponent} from '../../pages/public-pages/login/login.component';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {UserService} from '../data/user.service';
import {LoadInitControllerService} from '../controller/load-init-controller.service';
import { HttpErrorService } from './http-error.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  httpOptionsWithToken;

  snackBar: SnackbarComponent;

  constructor(
    private router: Router,
    private userService: UserService,
    private userprofileService: UserprofileService,
    private http: HttpClient,
    private loadinitController: LoadInitControllerService,
    private httpErrorService: HttpErrorService,
    snackbarComp: SnackbarComponent
  ) {
    this.snackBar = snackbarComp;
  }

  signupUser(user: RegisterUserInterface) {
    // this.userprofileService.registerUser(user);
    this.userService.registerUser(user);
  }

  signinUser(user: LoginUserInterface) {
    const body = JSON.stringify(user);
    this.http.post('/api/v1/auth/login', body, this.httpOptions).subscribe(
      data => {
        this.token = data;
        const BearerToken = 'Bearer ' + this.token.token;
        sessionStorage.setItem('bearerToken', BearerToken);
        this.httpOptionsWithToken = {
          headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': BearerToken})
        };
      },
      err => {
        console.log(err);
        this.httpErrorService.handleServerError(err);

      },
      () => {
        this.userService.loginUser();
        this.loadinitController.startLoadingData();
        this.snackBar.openSnackBar('Sie haben sich erfolgreich eingeloggt', 'Ok');

      },
    );
  }
  //Wenn Methode mit Parameter === 1 aufgerufen wird => Ausgabetext!
  logout(i: number) {
    if (i === 1){
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('bearerToken');
      sessionStorage.removeItem('userid');
      this.snackBar.openSnackBar('Sie haben sich erfolgreich ausgeloggt', 'Ok');

    }
    else {
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('bearerToken');
      sessionStorage.removeItem('userid');

    }

  }

  isUserLoggedIn() {
    let state = false;
    const bearerToken = sessionStorage.getItem('bearerToken');
    if ( bearerToken != null ) {
      state = true;
    }
    return state;
  }

  isAuthenticated() {
    const state = new Subject<boolean>();
    return state.asObservable();
  }

  public gethttpHeadersWithToken() {
    return this.httpOptionsWithToken;
  }

  // returns true when oldPw is the correct password of user with username 'name'
  changeUserPassword(name, oldPw, newPw) {
    const dict = {
      username: name,
      password: oldPw
    };
    const body = JSON.stringify(dict);
    this.http.post('/api/v1/auth/login', body, this.httpOptions).subscribe(
      data => {
        this.token = data;

        const BearerToken = 'Bearer ' + this.token.token;
        sessionStorage.setItem('bearerToken', BearerToken);
        this.httpOptionsWithToken = {
          headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: BearerToken})
        };
      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Fehler bei der Verifizierung, überprüfe deine Eingaben.', 'Ok');
      },
      () => {

        this.userService.updatePassword(name, newPw);
      },
    );
  }
}
