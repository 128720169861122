import { Injectable } from '@angular/core';
import {TeamService} from '../data2/team.service';
import {GoliveService} from '../data2/golive.service';
import {SettingsService} from '../settings/settings.service';
import {Router} from '@angular/router';
import {BookService} from '../data/book.service';
import {BookurlService} from '../data/bookurl.service';
import {ParserService} from '../data/parser.service';
import {LawService} from '../data/law.service';
import {UserService} from '../data/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoadInitControllerService {

  // tslint:disable-next-line:variable-name
  private _state;

  constructor(
    private bookService: BookService,
    private bookurlService: BookurlService,
    private parserService: ParserService,
    private lawService: LawService,

    private teamService: TeamService,
    private goliveService: GoliveService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router,
  ) { }

  public startLoadingData() {

    this._state = true;
    setTimeout(() => this.loadAllData(), 100);
  }

  starLoadingDataWithoutUser() {
    this.userService.loginUser();
  }

  public loadingController() {
    this.loadAllData();
    const timer = setInterval(() => this.loadAllData(), 100000);
  }

  public loadAllData(): void {
    if (this._state) {

      this.bookService.loadBooks();
      this.bookurlService.loadBookUrls();
      this.lawService.loadLaws();
      this.lawService.loadComments();
      this.lawService.loadReferences();
      this.lawService.loadMarkers();
      this.parserService.loadParsers();

      /*
      this.teamService.loadTeamOfCurrentUser();
      setTimeout(() => this.goliveService.loadGolivesOfCurrentTeam(), 100);
      if (this.goliveService.getGoLives() == undefined || this.goliveService.getGoLives() == null) {
        setTimeout(() => this.goliveService.loadGolivesOfCurrentTeam(), 200);
      }
      if (this.goliveService.getGoLives() == undefined || this.goliveService.getGoLives() == null) {
        setTimeout(() => this.goliveService.loadGolivesOfCurrentTeam(), 500);
      }
      */
    }
  }

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }
}
