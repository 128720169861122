import {EventEmitter, Injectable} from '@angular/core';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  courses;
  coursesChanged = new EventEmitter<any[]>();

  changeEvent = new EventEmitter<any>();

  httpOptionsWithToken;

  snackBar: SnackbarComponent;

  constructor() { }

  public getCourses() {
    return this.courses;
  }

  public getCourse(id: number) {
    for (const course of this.courses) {
      if (course.id === id) {
        return course;
      }
    }
    return null;
  }

}
