import {Component, Input, OnInit} from '@angular/core';
import {WebSocketAPI} from '../../../services/websocket/WebSocketAPI';
import {User} from '../../../models/team/user/user.model';
import {NotificationService} from '../../../services/data2/notification.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import {LoadInitControllerService} from '../../../services/controller/load-init-controller.service';
import {GoliveService} from '../../../services/data2/golive.service';
import {NotificationMessage} from '../../../models/notification/notification.model';
import {UserprofileService} from '../../../services/data2/userprofile.service';

@Component({
  selector: 'app-socket',
  templateUrl: './socket.component.html',
  styleUrls: ['./socket.component.scss']
})
export class SocketComponent implements OnInit {

  user: User;

  webSocketAPI: WebSocketAPI;
  messageToSend: string;
  messageToShow: any;

  webSocketStatus = false;

  durationInSeconds = 8;

  constructor(
    private notificationService: NotificationService,
    // tslint:disable-next-line:variable-name
    private _snackBar: MatSnackBar,
    private loadInitControllerService: LoadInitControllerService,
    private goliveService: GoliveService,
    private userService: UserprofileService,
  ) { }

  ngOnInit() {
    /*this.webSocketAPI = new WebSocketAPI(
      new SocketComponent(this.notificationService, this._snackBar, this.loadInitControllerService, this.goliveService, this.userService)
    );

    this.user = this.userService.getCurrentUser();
    this.userService.currentUserChanged.subscribe(
      (user: User) => {
        this.user = user;
      }
    );

    this.goliveService.changeEvent.subscribe(
      (dataa: string) => {
        this.sendUpdate(dataa);
      },
    );
    //just for tests
    //setTimeout(() => this.toggleConnect(), 5000);
    this.toggleConnect();
  }

  toggleConnect() {
    if (this.webSocketStatus) {
      this.webSocketStatus = false;
      this.disconnect();
    } else {
      this.webSocketStatus = true;
      this.connect()
    }
  }

  connect(){
    this.webSocketAPI._connect();
  }

  disconnect(){
    this.webSocketAPI._disconnect();
  }

  sendMessage(){

    var message = this.user.firstName + ": " + this.messageToSend;
    this.webSocketAPI._send(message);
  }

  handleMessage(message){
    this.messageToShow = JSON.stringify(message.body);
    let length = this.messageToShow.toString().length;
    this. messageToShow = this.messageToShow.toString().substring(16, length - 4);

    this.notificationService.addPersonNotification(this.messageToShow);
    this.openSnackBar(this.messageToShow, 'OK');
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000
    });
  }

  setConnected() {
    this.webSocketStatus = true;
  }

  handleUpdate(message) {
    this.loadInitControllerService.loadAllData();
    this.handleMessage(message);
  }

  sendUpdate(data: string) {
    this.webSocketAPI._sendChanges(data);
  }
     */
  }
}
