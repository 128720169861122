import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }

  openSnackBar(message: string, action: string) {

    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
