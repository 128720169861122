import {EventEmitter, Injectable} from '@angular/core';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {TeamService} from '../data2/team.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BookurlService {

  bookUrls;
  booksUrlsChanged = new EventEmitter<any[]>();

  httpOptionsWithToken;
  snackBar: SnackbarComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private teamService: TeamService,
    private userService: UserService,
    snackbarComp: SnackbarComponent
  ) {
    this.snackBar = snackbarComp;
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: sessionStorage.getItem('bearerToken')
      })
    };
  }

  loadBookUrls() {
    this.http.get('/api/v1/bookurls', this.httpOptionsWithToken).subscribe(
      data => {
        this.bookUrls = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Bücher konnten nicht geladen werden', 'Ok');
      },
      () => {

        this.booksUrlsChanged.emit(this.bookUrls);
      },
    );
  }

  public getBookUrls() {

    return this.bookUrls;
  }

  public getBookUrl(id: number) {
    for (const bookUrl of this.bookUrls) {
      if (bookUrl.id === id) {
        return bookUrl;
      }
    }
    return null;
  }
}
