import {EventEmitter, Injectable} from '@angular/core';
import {UserprofileService} from '../data2/userprofile.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Settings} from '../../models/team/user/settings.model';
import set = Reflect.set;

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings = new Settings(
    true,
    true,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    true,
    );

  constructor(
    private userprofileService: UserprofileService,
    private http: HttpClient,
  ) { }

  // Lädt die Einstellungen des aktuellen Nutzers

  public loadSettingsOfCurrentUser() {
    if (this.userprofileService.getCurrentUser() !== undefined) {
      this.settings = this.userprofileService.getCurrentUser().settings;
    }
  }


  // Updated die Einstellungen
  public updateSettings() {
    this.userprofileService.updateUserSettings(this.settings);
  }

  public getshowTodayGoLiveCountOnDashboard(): boolean {
    return this.settings.showTodayGoLiveCountOnDashboard;
  }

  public setshowTodayGoLiveCountOnDashboard(value: boolean) {
    this.settings.showTodayGoLiveCountOnDashboard = value;
  }

  public getshowWeekGoLiveCountOnDashboard(): boolean {
    return this.settings.showWeekGoLiveCountOnDashboard;
  }

  public setshowWeekGoLiveCountOnDashboard(value: boolean) {
    this.settings.showWeekGoLiveCountOnDashboard = value;
  }

  public getshowMonthGoLiveCountOnDashboard(): boolean {
    return this.settings.showMonthGoLiveCountOnDashboard;
  }

  public setshowMonthGoLiveCountOnDashboard(value: boolean) {
    this.settings.showMonthGoLiveCountOnDashboard = value;
  }

  public getshowYearGoLiveCountOnDashboard(): boolean {
    return this.settings.showYearGoLiveCountOnDashboard;
  }

  public setshowYearGoLiveCountOnDashboard(value: boolean) {
    this.settings.showYearGoLiveCountOnDashboard = value;
  }

  public getshowProgressBarGoLiveOnDashboard(): boolean {
    return this.settings.showProgressBarGoLiveOnDashboard;
  }

  public setshowProgressBarGoLiveOnDashboard(value: boolean) {
    this.settings.showProgressBarGoLiveOnDashboard = value;
  }

  public getshowTodayRunbookCountOnDashboard(): boolean {
    return this.settings.showTodayRunbookCountOnDashboard;
  }

  public setshowTodayRunbookCountOnDashboard(value: boolean) {
    this.settings.showTodayRunbookCountOnDashboard = value;
  }

  public getshowWeekRunbookCountOnDashboard(): boolean {
    return this.settings.showWeekRunbookCountOnDashboard;
  }

  public setshowWeekRunbookCountOnDashboard(value: boolean) {
    this.settings.showWeekRunbookCountOnDashboard = value;
  }

  public getshowMonthRunbookCountOnDashboard(): boolean {
    return this.settings.showMonthRunbookCountOnDashboard;
  }

  public setshowMonthRunbookCountOnDashboard(value: boolean) {
    this.settings.showMonthRunbookCountOnDashboard = value;
  }

  public getshowYearRunbookCountOnDashboard(): boolean {
    return this.settings.showYearRunbookCountOnDashboard;
  }

  public setshowYearRunbookCountOnDashboard(value: boolean) {
    this.settings.showYearRunbookCountOnDashboard = value;
  }

  public getshowProgressBarRunbookOnDashboard(): boolean {
    return this.settings.showProgressBarRunbookOnDashboard;
  }

  public setshowProgressBarRunbookOnDashboard(value: boolean) {
    this.settings.showProgressBarRunbookOnDashboard = value;
  }

  public getshowTodayRollbackCountOnDashboard(): boolean {
    return this.settings.showTodayRollbackCountOnDashboard;
  }

  public setshowTodayRollbackCountOnDashboard(value: boolean) {
    this.settings.showTodayRollbackCountOnDashboard = value;
  }

  public getshowWeekRollbackCountOnDashboard(): boolean {
    return this.settings.showWeekRollbackCountOnDashboard;
  }

  public setshowWeekRollbackCountOnDashboard(value: boolean) {
    this.settings.showWeekRollbackCountOnDashboard = value;
  }

  public getshowMonthRollbackCountOnDashboard(): boolean {
    return this.settings.showMonthRollbackCountOnDashboard;
  }

  public setshowMonthRollbackCountOnDashboard(value: boolean) {
    this.settings.showMonthRollbackCountOnDashboard = value;
  }

  public getshowYearRollbackCountOnDashboard(): boolean {
    return this.settings.showYearRollbackCountOnDashboard;
  }

  public setshowYearRollbackCountOnDashboard(value: boolean) {
    this.settings.showYearRollbackCountOnDashboard = value;
  }

  public getshowProgressBarRollbackOnDashboard(): boolean {
    return this.settings.showProgressBarRollbackOnDashboard;
  }

  public setshowProgressBarRollbackOnDashboard(value: boolean) {
    this.settings.showProgressBarRollbackOnDashboard = value;
  }
}
