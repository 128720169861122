import {EventEmitter, Injectable} from '@angular/core';
import {User} from '../../models/team/user/user.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RegisterUserInterface} from '../../interfaces/register-user.interface';
import {LoginUserInterface} from '../../interfaces/login-user.interface';
import {Router} from '@angular/router';
import {Settings} from '../../models/team/user/settings.model';
import {LoadInitControllerService} from '../controller/load-init-controller.service';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  currentUserChanged = new EventEmitter<any[]>();
  currentUser;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  httpOptionsWithToken;

  snackBar: SnackbarComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    snackBar: SnackbarComponent
  ) {
    this.snackBar = snackBar;
  }

  // lädt den aktuellen User
  public loadUser(userId: number) {
    let user;
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };

    this.http.get('/api/v1/users/' + userId, this.httpOptionsWithToken).subscribe(
      data => { this.currentUser = data } ,
      err => {
        console.error(err);
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('bearerToken');
        sessionStorage.removeItem('userid');
        this.router.navigate(['/login']);
      },
      () => {

        this.currentUserChanged.emit(this.currentUser);
      }
    );
  }

  // lädt alle User aus der Datenbank -> Nur das eigene Team darf gesehen werden
  public loadAllUsers() {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let users;
    this.http.get('/api/v1/users/all/', this.httpOptionsWithToken).subscribe(
      data => { users = data} ,
      err => console.error(err),
      () => console.log('Users were loaded')
    );
    return users;
  }

  // stellt sicher, dass sich der Nutzer nur selbst bearbeiten kann
  public updateUser(user: User) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(user);

    this.http.put('/api/v1/users/update' , body, this.httpOptionsWithToken).subscribe(
      data => { this.currentUser = data} ,
      err => console.error(err),
      () => console.log('User was updated')
    );
  }

  // stellt sicher, dass sich der Nutzer nur selbst bearbeiten kann
  public updateUserSettings(settings: Settings) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    this.currentUser.settings = settings;
    let body = JSON.stringify(this.currentUser);

    this.http.put('/api/v1/users/update' , body, this.httpOptionsWithToken).subscribe(
      data => { this.currentUser = data} ,
      err => console.error(err),
      () => console.log('User was updated')
    );
  }

  // registriert einen neuen Nutzer
  public registerUser(user: RegisterUserInterface) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    let body = JSON.stringify(user);


    let registeredUser;
    this.http.post('/api/v1/users/register', body, this.httpOptionsWithToken).subscribe(
      data => { registeredUser = data } ,
      err => {
        console.error(err);
        this.snackBar.openSnackBar("Die Registrierung hat leider nicht funktioniert", "Ok");
      },
      () => {


        this.snackBar.openSnackBar("Sie haben sich erfolgreich registriert", "Ok");
      },
    );
  }

  // loggt einen User ein
  public loginUser(user: LoginUserInterface) {
    let body = JSON.stringify(user);
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    this.http.post('/api/v1/auth/login', body, this.httpOptionsWithToken).subscribe(
      data => {
        this.currentUser = data;
        this.currentUserChanged.emit(this.currentUser)
      },
      err => console.error(err),
      () => {



        //this.router.navigateByUrl("/dashboard");
        //sessionStorage.setItem('username', user.username);
        //sessionStorage.setItem('userid', this.currentUser.id);


        return true;
      },
    );
    return false
  }

  // wird benötigt, weil Avatar extra bearbeitet werden kann und die Componente keinen Zugriff auf die sonstigen Userdaten hat
  public setAvatar(avatar: string) {
    if (this.currentUser != null) {
      this.currentUser.avatar = avatar;
    }

    this.updateUser(this.currentUser);
  }

  // gibt anderen Componenten den aktuellen User
  public getCurrentUser() {
    return this.currentUser;
  }

  public getCurrentUserId() {
    if (this.currentUser != undefined) {
      return this.currentUser.id;
    } else if (sessionStorage.getItem('userid') != null) {
      return sessionStorage.getItem('userid');
    } else {
      this.router.navigateByUrl("/login");
    }

  }

  public getCurrentUserFirstName() {
    return this.currentUser.firstName;
  }

}
