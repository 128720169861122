import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {NotificationMessage} from '../../models/notification/notification.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications = [
    new NotificationMessage(0, 'Test', 'nix', 'toll'),
  ];
  notificationsChanged = new EventEmitter<any[]>();
  IDs = 1;

  constructor(private http: HttpClient, private router: Router) { }

  public getNotifications() {
    return this.notifications;
  }

  addPersonNotification(message: string) {
    this.notifications.push(new NotificationMessage(this.IDs , message , 'icon-single-02', 'info'));
    this.IDs++;
    this.notificationsChanged.emit(this.notifications);
  }

  public deleteNotification(id: number) {
    let pos = 0;
    let founded = false;
    for (let notification of this.notifications) {
      if (notification.id == id || founded == true) {
        notification[pos] = notification[pos + 1];
        founded = true;
      }
      pos++;
    }
    this.notifications.length--;
    this.notificationsChanged.emit(this.notifications);
  }
}
