import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {
  constructor(private snackBar: SnackbarComponent) {}

  handleServerError(error: HttpErrorResponse) {
    if (error instanceof ErrorEvent) {
      // Clientseitiger Fehler
      this.snackBar.openSnackBar('Ein Fehler ist aufgetreten.', 'Ok');
    } else {
      // Serverseitiger Fehler
      const errorMessage = error.message || 'Ein unbekannter Fehler ist aufgetreten.';
      this.snackBar.openSnackBar(errorMessage, 'Ok');
    }
  }
}
