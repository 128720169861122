import {EventEmitter, Injectable} from '@angular/core';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {RegisterUserInterface} from '../../interfaces/register-user.interface';
import {LoginUserInterface} from '../../interfaces/login-user.interface';
import {User} from '../../models/team/user/user.model';
import {Observable} from 'rxjs';
import { HttpErrorService } from '../security/http-error.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUserChanged = new EventEmitter<any[]>();
  currentUser;

  httpOptionsWithToken;
  userdata;

  snackBar: SnackbarComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    snackBar: SnackbarComponent,
    private httpErrorService: HttpErrorService,
  ) {
    this.snackBar = snackBar;
  }

  private getBearerToken() {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: sessionStorage.getItem('bearerToken')})
    };
  }

  public loadAllUsers(): Observable<any> {
    this.getBearerToken();
    return this.http.get('/api/v1/users', this.httpOptionsWithToken);
  }

  // TBD, auch im Backend überprüfen! stellt sicher, dass sich der Nutzer selbst bearbeiten kann (ausgenommen vom Admin)
  public updateUser(user: User, updateCurrent: boolean) {
    this.getBearerToken();
    const body = JSON.stringify(user);

    this.http.put('/api/v1/users/update' , body, this.httpOptionsWithToken).subscribe(
      data => { if (updateCurrent) { this.currentUser = data; } } ,
      err => console.error(err),
      () => {

        this.snackBar.openSnackBar('Die Änderungen wurden erfolgreich gespeichert', 'Ok');
      }
    );
  }

  public updateUsername(prevName, newName) {
    this.getBearerToken();
    const dict = {
      username: prevName,
      newUsername: newName
    };
    const body = JSON.stringify(dict);
    this.http.put('/api/v1/users/updateUsername', body, this.httpOptionsWithToken).subscribe(
      data => { },
      error => console.error(error),
      () => console.log('Username was updated')
    );
  }

  public updatePassword(name, pw) {
    this.getBearerToken();
    const dict = {
      username: name,
      password: pw
    };
    const body = JSON.stringify(dict);
    this.http.put('/api/v1/users/updatePassword', body, this.httpOptionsWithToken).subscribe(
      data => { },
      error => {
        console.error(error);
        this.snackBar.openSnackBar('Fehler: Passwort konnte nicht geändert werden.', 'Ok');
        },
      () => {

        this.snackBar.openSnackBar('Passwort erfolgreich geändert.', 'Ok');
      }
    );
  }

  // registriert einen neuen Nutzer -> schon fertig implementiert (TN)
  public registerUser(user: RegisterUserInterface) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    // Testen auf Zulässigkeit des Nutzernamens, Registrierung wird abgebrochen wenn unzulässig
    const usernameRegex = new RegExp('[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?');
    if (!usernameRegex.test(user.username)) {
      this.snackBar.openSnackBar('Der Nutzername ist nicht zulässig. Format: nutzername@anbieter.domain', 'Ok');
      return;
    }

    // Zuweisung Rolle student
    user.student = true;

    const body = JSON.stringify(user);
    let registeredUser;

    this.http.post('/api/v1/users/register', body, this.httpOptionsWithToken).subscribe(
      data => { registeredUser = data; } ,
      err => {
        console.log(err);
        this.httpErrorService.handleServerError(err);
      },
      () => {
        this.snackBar.openSnackBar('Sie haben sich erfolgreich registriert', 'Ok');
      },
    );
  }

  // loggt einen User ein -> schon fertig implementiert (TN)
  public loginUser() {
    this.getBearerToken();
    this.http.get('/api/v1/auth/user', this.httpOptionsWithToken).subscribe(
      data => {
        this.userdata = data;
        this.currentUser = this.userdata.user;
        this.currentUserChanged.emit(this.currentUser);
        if (this.currentUser.avatarUrl == null) {
          this.currentUser.avatarUrl = 'avatar1';
        }
      }, err => {
      console.error(err);
      this.snackBar.openSnackBar('User konnte nicht geladen werden. Logge aus', 'Ok');
      this.router.navigateByUrl('/login');
    },
      () => {



        if(this.router.url === "/login" || this.router.url === "/login?returnUrl=%2Fdashboard"){
          this.router.navigateByUrl('/dashboard?r');
        }
        sessionStorage.setItem('userid', this.currentUser.id);

        return true;
      },
    );
    return false;
  }

  public isUserAdmin() {
    let state = false;
    this.loginUser()
    if (this.currentUser != null) {
      if (this.currentUser.admin === true) {
        state = true;
      }
    }
    return state;
  }

  // wird benötigt, weil Avatar extra bearbeitet werden kann und die Componente keinen Zugriff auf die sonstigen Userdaten hat
  public setAvatar(avatar: string) {
    if (this.currentUser != null) {
      this.currentUser.avatarUrl = avatar;
    }

    this.updateUser(this.currentUser, true);
  }

  // gibt anderen Componenten den aktuellen User
  public getCurrentUser() {
    if (this.currentUser != null) {
      return this.currentUser;
    }
  }

  // gibt anderen Componenten den aktuellen User
  public getCurrentUserId() {
    if (this.currentUser !== undefined) {
      return this.currentUser.id;
    } else if (sessionStorage.getItem('userid') != null) {
      return sessionStorage.getItem('userid');
    } else {
      this.router.navigateByUrl('/login');
    }

  }

  public getCurrentUserFirstName() {
    return this.currentUser.firstName;
  }

  public removeUser(userId) {
    this.getBearerToken();
    this.http.delete('/api/v1/users/' + userId, this.httpOptionsWithToken).subscribe(
      data => { },
      error => console.error(error),
      () => console.log('User was removed.')
    );
  }
}
