import { User } from '../../team/user/user.model';
import { Book } from '../book.model';
import { Law } from '../law/law.model';
import { ITags } from './ITags';

export class Comment implements ITags {
  constructor(
    public id: number,
    public colour: string,
    public position: number,
    public law: Law,
    public user: User,
    public text: string,
    public length: number
  ) { }

  public getBook() {

  }
}
