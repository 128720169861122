import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {TeamService} from '../data2/team.service';
import {UserService} from './user.service';
import {Book} from '../../models/book/book.model';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';
import {CreateBookInterface} from '../../interfaces/create-book.interface';
import {ParseBookInterface} from '../../interfaces/parse-book.interface';
import {BookUrl} from '../../models/book/bookurl.model';
import { Reference } from 'src/app/models/book/tags/reference';
import { Marker } from 'src/app/models/book/tags/marker';
import { ITags } from 'src/app/models/book/tags/ITags';
import { DataObjectifier } from '../pipes/objectifier';
import { Comment } from 'src/app/models/book/tags/comment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  private objectifier = new DataObjectifier();


  books;
  booksChanged = new EventEmitter<any[]>();
  rollbackTasksChanged = new EventEmitter<any[]>();

  laws;
  lawsChanged = new EventEmitter<any[]>();

  comments;
  public oComments: Comment[];
  commentsChanged = new EventEmitter<any[]>();

  references;
  public oReferences: Reference[];
  referencesChanged = new EventEmitter<any[]>();

  markers;
  public oMarkers: Marker[];
  markersChanged = new EventEmitter<any[]>();

  changeEvent = new EventEmitter<any>();

  httpOptionsWithToken;

  snackBar: SnackbarComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private teamService: TeamService,
    private userService: UserService,
    snackbarComp: SnackbarComponent
  ) {
    this.snackBar = snackbarComp;
  }

  private getBearerToken() {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: sessionStorage.getItem('bearerToken')
      })
    };
  }

  loadAllBooks(): Observable<any> {
    this.getBearerToken();
    const url = `/api/v1/books`;
    return this.http.get<any>(url,  this.httpOptionsWithToken );

  }

  // Gesetzestexte/Bücher
  // Alle Bücher laden
  loadBooks() {
    this.getBearerToken();

    return this.http.get('/api/v1/books', this.httpOptionsWithToken);
  }

  // Ein Buch laden
  loadBook (bookId: number): Observable<any>{
    this.getBearerToken();
    return this.http.get('/api/v1/books/'+ bookId, this.httpOptionsWithToken);
  }

  // Neues Buch hinzufügen
  createBook(book: BookUrl) {

    // tslint:disable-next-line:new-parens
    const newBook: CreateBookInterface = new class implements CreateBookInterface {
      code: string;
      title: string;
      version: number;
    };

    // tslint:disable-next-line:new-parens
    const newBookLaws: ParseBookInterface = new class implements ParseBookInterface {
      // tslint:disable-next-line:variable-name
      book_id: number;
      parser: string;
      url: string;
    };

    newBook.title = book.title;
    newBook.version = book.version;
    newBook.code = book.code;

    this.getBearerToken();
    const body = JSON.stringify(newBook);
    return this.http.post('/api/v1/books/create', body, this.httpOptionsWithToken);
  }


  // Test Noah
  AddNewBook(book: BookUrl) {
    this.getBearerToken();
    const body = JSON.stringify(book);

    return this.http.post('/api/v1/books/addnew', body, this.httpOptionsWithToken);
  }

  // von anderen Components aus erreichbar
  public getBooks() {
    if (this.books != null) {
      return this.books;
    }
    return null;
  }

  // Buch löschen
  public deleteBook(bookid: number) {
    this.getBearerToken();
    this.snackBar.openSnackBar('Das Gesetzbuch wurde erfolgreich gelöscht!', 'Ok');
    return this.http.delete('/api/v1/books/' + bookid, this.httpOptionsWithToken);
  }
  // Favoriten laden
  public loadFavoriteBooks () : Observable<any>{
    this.getBearerToken();
    return this.http.get(`/api/v1/books/favorites`, this.httpOptionsWithToken);
  }


  //Buch Favoriten-Status hinzufügen
  public addFavoriteBook (bookid: number){
    this.getBearerToken();
    const body = {};
    return this.http.post(`/api/v1/books/${bookid}/favorite`, body, this.httpOptionsWithToken);
  }

  //Buch Favoriten-Status löschen
  public deleteFavoriteBook (bookid: number){
    this.getBearerToken();
    return this.http.delete(`/api/v1/books/${bookid}/favorite`, this.httpOptionsWithToken);
  }


  // LAWS


  // Paragraphen/Laws
  // -> eigentlich unnötig
  loadLaws() {
    this.getBearerToken();
    this.http.get('/api/v1/laws', this.httpOptionsWithToken).subscribe(
      data => {
        this.laws = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Paragraphen konnten nicht geladen werden', 'Ok');
      },
      () => {
        this.lawsChanged.emit(this.laws);
      },
    );
  }

  loadLawsOfBook(bookId: number) : Observable<any> {
    this.getBearerToken();
    return this.http.get('/api/v1/laws/book/' + bookId, this.httpOptionsWithToken);
  }

  createLaw(law: ParseBookInterface) {
    this.getBearerToken();
    const body = JSON.stringify(law);
    this.http.post('/api/v1/laws', body, this.httpOptionsWithToken).subscribe(
      data => {
      //  const law = data;

      },
      err => {
        console.error(err);
        this.snackBar.openSnackBar('Die Paragraphen konnten leider nicht hinzugefügt werden. Überprüfe deine Daten', 'Ok');
      },
      () => {
        // this.userprofileService.loginUser(user);
        this.snackBar.openSnackBar('Gesetzesbuch wurde erfolgreich geladen', 'Ok');
        this.loadBooks();
        this.loadLawsOfBook(law.book_id);
      },
    );
  }

  public getLaws() {
    return this.laws;
  }

  public getLawsOfBook(bookId: number) {
    const lawsOfBook = [];
    for (const law of this.books[bookId].laws) {
      if (law.book.id === bookId) {
        return law;
      }
    }
    return null;
  }

  // funktioniert nicht
  public getLaw(id: number) {
    for (const law of this.laws) {
      if (law.id === id) {
        return law;
      }
    }
    return null;
  }






  // Kommentare
  // Alle Kommentare laden
  loadAllComments(): Observable<any> {
    this.getBearerToken();
    const url = `/api/v1/comments`;
    return this.http.get<any>(url,  this.httpOptionsWithToken );
  }

  // Kommentar erstellen
  createComment(comment: Comment) {
    this.getBearerToken();
    const body = JSON.stringify(comment);
    return this.http.post('/api/v1/comments/', body, this.httpOptionsWithToken);
  }

  // Kommentar löschen
  public deleteComment(commentid: number) {
    this.getBearerToken();
    return this.http.delete('/api/v1/comments/' + commentid, this.httpOptionsWithToken);
  }

  // Kommentar bearbeiten
  public updateComment(comment: Comment) {
    this.getBearerToken();
    const body =JSON.stringify(comment);
    return this.http.post('/api/v1/comments/' +comment.id, body, this.httpOptionsWithToken);
  }



  // Referenzen
  // Verlinkungen laden
  loadLinks() : Observable<any> {
    this.getBearerToken();
    return this.http.get('/api/v1/references', this.httpOptionsWithToken);
  }

  // Verlinkung erstellen
  createLawLink(reference: Reference) {
    this.getBearerToken();
    //const body = JSON.stringify(reference);
    const body = {"colour":reference.colour, "law_id": reference.law.id, "target_law_id": reference.targetLaw.id};
    return this.http.post('/api/v1/references', body, this.httpOptionsWithToken);
  }

  // Verlinkung löschen
  deleteLawLink(linkId: number) {
    this.getBearerToken();
    return this.http.delete('/api/v1/references/' + linkId, this.httpOptionsWithToken);
  }




  // Markierungen
  // Alle Markierungen laden
  loadAllMarkers(): Observable<any> {
    this.getBearerToken();
    const url = `/api/v1/markers`;

    return this.http.get<any>(url,  this.httpOptionsWithToken );
  }

  // Markierungen erstellen
  createMarker(marker: any) {
    this.getBearerToken();
    const body = JSON.stringify(marker);

    return this.http.post('/api/v1/markers/create', body, this.httpOptionsWithToken);
  }

  // Markierungen löschen
  public deleteMarker(markerid: number) {
    this.getBearerToken();
    return this.http.delete('/api/v1/markers/' + markerid, this.httpOptionsWithToken);
  }

  // TODO: We need to call this method to show all the tags in the right Tag area of Bookdetail Component
  public getTagsOfBook(bookId: number): ITags[] {
    this.comments.forEach(element => {
      // Objectify all the stored data so we get Instances of Comment/Reference/Marker
      // See file: src/app/services/pipes/objectifier.ts
    });

    return;
  }

}
