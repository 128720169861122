import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/data/user.service';
import {BookService} from '../../services/data/book.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTESADMIN: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'icon-components',
    class: '',
  },
  {
    path: '/books',
    title: 'Gesetzestexte',
    icon: 'icon-paper',
    class: ''
  },
  {
    path: '/profile',
    title: 'Profil',
    icon: 'icon-single-02',
    class: ''
  },
  {
    path: '/database',
    title: 'Datenbank',
    icon: 'icon-bullet-list-67 ',
    class: 'admin'
  },
  {
    path: '/courses',
    title: 'Nutzerverwaltung',
    icon: 'icon-molecule-40',
    class: 'admin',
  },
  {
    path: '/settings',
    title: 'Einstellungen',
    icon: 'icon-settings-gear-63',
    class: 'admin'
  },
  {
    path: '/documentation',
    title: 'Hilfe',
    icon: 'icon-bulb-63',
    class: '',
  },
];


/*Verfügbare Links/Seiten für normale User => zunkünftig über class steuern?*/
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'icon-components',
    class: '',
  },
  {
    path: '/books',
    title: 'Gesetzestexte',
    icon: 'icon-paper',
    class: ''
  },
  {
    path: '/profile',
    title: 'Profil',
    icon: 'icon-single-02',
    class: ''
  },
  {
    path: '/documentation',
    title: 'Hilfe',
    icon: 'icon-bulb-63',
    class: '',
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    /*Timeout notwendig, sonst funktioniert die Methode testAdmin nicht*/
    setTimeout(() => this.testAdmin(), 150);
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  private testAdmin() {
    if (this.userService.isUserAdmin()) {
      this.menuItems = ROUTESADMIN.filter(menuItem => menuItem);
    } else {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
  }
}
