export class Settings {

  constructor(

    public showTodayGoLiveCountOnDashboard: boolean,
    public showWeekGoLiveCountOnDashboard: boolean,
    public showMonthGoLiveCountOnDashboard: boolean,
    public showYearGoLiveCountOnDashboard: boolean,
    public showProgressBarGoLiveOnDashboard: boolean,

    public showTodayRunbookCountOnDashboard: boolean,
    public showWeekRunbookCountOnDashboard: boolean,
    public showMonthRunbookCountOnDashboard: boolean,
    public showYearRunbookCountOnDashboard: boolean,
    public showProgressBarRunbookOnDashboard: boolean,

    public showTodayRollbackCountOnDashboard: boolean,
    public showWeekRollbackCountOnDashboard: boolean,
    public showMonthRollbackCountOnDashboard: boolean,
    public showYearRollbackCountOnDashboard: boolean,
    public showProgressBarRollbackOnDashboard: boolean,

  ) {}

}
