import { Component, OnInit } from '@angular/core';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/login',
    title: 'Login',
    icon: 'icon-components',
    class: ''
  },
  {
    path: '/documentation',
    title: 'Documentation',
    icon: 'icon-spaceship',
    class: ''
  },
  {
    path: '/versions',
    title: 'Versions',
    icon: 'icon-laptop',
    class: ''
  }

];

@Component({
  selector: 'app-public-sidebar',
  templateUrl: './public-sidebar.component.html',
  styleUrls: ['./public-sidebar.component.css']
})
export class PublicSidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
