import {EventEmitter, Injectable} from '@angular/core';
import {GoLive} from '../../models/golive/golive.model';
import {Runbooktask} from '../../models/golive/runbooktask.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {TeamService} from './team.service';
import {User} from '../../models/team/user/user.model';
import {TaskStatus} from '../../models/Status/TaskStatus.model';
import {Rollbacktask} from '../../models/golive/rollbacktask.model';
import {UserprofileService} from './userprofile.service';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class GoliveService {

  golivesChanged = new EventEmitter<any[]>();
  runbookTasksChanged = new EventEmitter<any[]>();
  rollbackTasksChanged = new EventEmitter<any[]>();
  changeEvent = new EventEmitter<any>();

  golives;
  runbooktasks;
  rollbacktasks;

  httpOptionsWithToken;

  constructor(
    private http: HttpClient,
    private router: Router,
    private teamService: TeamService,
    private userService: UserprofileService,
  ) { }

  public loadGolivesOfCurrentTeam() {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };

    if (this.teamService.getCurrentTeam() != undefined) {
      let currentTeamId = this.teamService.getCurrentTeamId();
      this.http.get('/api/v1/golives/findbyteam/' + currentTeamId, this.httpOptionsWithToken).subscribe(
        data => {
          //this.golives = data;
          this.updateData(data);
          this.golivesChanged.emit(this.golives);

          this.updateProgresses();
          this.getRunbookTasks();
          this.getRollbackTasks();
        } ,
        err => console.error(err),
        () => {

          this.golivesChanged.emit(this.golives);
        },
      );
    }
  }

  private updateData(data: Object) {
    let newGolives = data;
    let GLpos = 0;
    for (let golive in this.golives) {
      if (this.golives[GLpos] != null || this.golives[GLpos] != undefined ) {
        if (newGolives[GLpos] != null || newGolives[GLpos] != undefined ) {
          newGolives[GLpos].open = this.golives[GLpos].open;
        }
        let Tpos = 0;
        if (this.golives[GLpos].runbookTasks != null || this.golives[GLpos].runbookTasks != undefined) {
          for (let tasks in this.golives[GLpos].runbookTasks ) {
            if (this.golives[GLpos].runbookTasks[Tpos] != null || this.golives[GLpos].runbookTasks[Tpos] != undefined) {
              if (newGolives[GLpos].runbookTasks[Tpos] != null || newGolives[GLpos].runbookTasks[Tpos] != undefined) {
                newGolives[GLpos].runbookTasks[Tpos].open = this.golives[GLpos].runbookTasks[Tpos].open;
              }
            }
            Tpos++;
          }
        }
        Tpos = 0;
        if (this.golives[GLpos].rollbackTasks != null || this.golives[GLpos].rollbackTasks != null) {
          for (let tasks in this.golives[GLpos].rollbackTasks ) {
            if (this.golives[GLpos].rollbackTasks[Tpos] != null || this.golives[GLpos].rollbackTasks[Tpos] != undefined) {
              if (newGolives[GLpos].rollbackTasks[Tpos] != null || newGolives[GLpos].rollbackTasks[Tpos] != undefined) {
                newGolives[GLpos].rollbackTasks[Tpos].open = this.golives[GLpos].rollbackTasks[Tpos].open;
              }
            }
            Tpos++;
          }
        }
      }
      GLpos++;
    }
    this.golives = newGolives;
  }

  // internal Update for toggles
  public updateInternal(goLive: GoLive) {
    let pos = 0;
    for (let golive in this.golives) {
      if (this.golives[pos].id == goLive.id) {
        this.golives[pos] = goLive;
      }
      pos++;
    }
  }

  public updateInternalRunbookTask(runbookTask: Runbooktask) {
    let GLpos = 0;
    for (let golive in this.golives) {
      let Tpos = 0;
      if (this.golives[GLpos].runbookTasks != null || this.golives[GLpos].runbookTasks != undefined ) {
        for (let task of this.golives[GLpos].runbookTasks) {
          if (task.id == runbookTask.id) {
            this.golives[GLpos].runbookTasks[Tpos] = runbookTask;
          }
          Tpos++;
        }
      }
      GLpos++;
    }
  }

  public updateInternalRollbackTask(rollbackTask: Rollbacktask) {
    let GLpos = 0;
    for (let golive in this.golives) {
      let Tpos = 0;
      if (this.golives[GLpos].rollbackTasks != null || this.golives[GLpos].rollbackTasks != undefined) {
        for (let task of this.golives[GLpos].rollbackTasks) {
          if (task.id == rollbackTask.id) {
            this.golives[GLpos].rollbackTasks[Tpos] = rollbackTask;
          }
          Tpos++;
        }
      }
      GLpos++;
    }
  }

  // update GoLive
  public updateGoLive(golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(golive);

    let golivee;
    this.http.put('/api/v1/golives/update' , body, this.httpOptionsWithToken).subscribe(
      data => {
        golivee = data;
        } ,
      err => console.error(err),
      () => {

        let string = this.userService.getCurrentUserFirstName() +': Runbook ' + golive.name + ' has been updated';

        this.changeEvent.emit(string);
      }
    );
  }

  public setAllTasksToDone(golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(golive);

    let golivee;
    this.http.put('/api/v1/golives/setalltodone' , body, this.httpOptionsWithToken).subscribe(
      data => {
        golivee = data;
      } ,
      err => console.error(err),
      () => {

        let string = this.userService.getCurrentUserFirstName() +': Runbook ' + golive.name + ' has been set to done';

        this.changeEvent.emit(string);
      }
    );
  }

  // update Runbooktask
  public updateRunbookTask(task: Runbooktask) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(task);

    let golivee;
    this.http.put('/api/v1/runbooktasks/update' , body, this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {


        let string = this.userService.getCurrentUserFirstName() +': Runbooktask ' + task.name + ' has been updated';

        this.changeEvent.emit(string);
      }
    );
  }

  // update Rollbacktask
  public updateRollbackTask(task: Rollbacktask) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(task);

    let golivee;
    this.http.put('/api/v1/rollbacktasks/update' , body, this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {


        let string = this.userService.getCurrentUserFirstName() +': Rollbacktask ' + task.name + ' has been updated';

        this.changeEvent.emit(string);
      }
    );
  }

  // create GoLive
  public createGoLive(golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let count = this.golives.length;
    this.golives[count] = golive;
    this.golivesChanged.emit(this.golives);
    let body = JSON.stringify(golive);

    let golivee;
    this.http.post('/api/v1/golives/new' , body, this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {

        let string = this.userService.getCurrentUserFirstName() +': Runbook ' + golive.name + ' has been created';

        this.changeEvent.emit(string);
      }
    );
  }

  // create Runbooktask
  public createRunbookTask(task: Runbooktask) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(task);

    let golivee;
    this.http.post('/api/v1/runbooktasks/new' , body, this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {

        let string = this.userService.getCurrentUserFirstName() +': Runbooktask ' + task.name + ' has been created';

        this.changeEvent.emit(string);
      }
    );
  }

  // create RollbackTasks
  public createRollbackTask(task: Rollbacktask) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
    let body = JSON.stringify(task);

    let golivee;
    this.http.post('/api/v1/rollbacktasks/new' , body, this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {

        let string = this.userService.getCurrentUserFirstName() +': Rollbacktask ' + task.name + ' has been created';

        this.changeEvent.emit(string);
      }
    );
  }

  // delete
  public deleteGolive(golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };


    let goliveId = golive.id;

    let deletedPosition = 0;
    let founded = false;
    for (let golive of this.golives) {
      if (goliveId == golive.id || founded == true) {
        this.golives[deletedPosition] = this.golives[deletedPosition + 1];
        founded = true;
      }
      deletedPosition++;
    }
    this.golives.length--;


    this.golivesChanged.emit(this.golives);
    let golivee;
    this.http.delete('/api/v1/golives/delete/' + goliveId , this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {

        let string = this.userService.getCurrentUserFirstName() +': Runbook ' + golive.name + ' was deleted';

        this.changeEvent.emit(string);
      }
    );
  }

  // delete
  public deleteRunbooktask(task: Runbooktask, golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };


    let goliveId = golive.id;
    let taskId = task.id;

    let deletedPosition = 0;
    let founded = false;
    for (let golive of this.golives) {
      if (goliveId == golive.id) {
        for (let task of golive.runbookTasks) {
          if (taskId == task.id) {
            golive.runbookTasks[deletedPosition] = golive.runbookTasks[deletedPosition + 1];
            founded = true;
          }
          deletedPosition++;
        }
        golive.runbookTasks.length--;
      }
    }


    this.golivesChanged.emit(this.golives);
    let golivee;
    this.http.delete('/api/v1/runbooktasks/delete/' + taskId , this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => console.log('Runbooktask was deleted?' + golivee)
    );
  }

  // delete
  public deleteRollbacktask(task: Rollbacktask, golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };


    let goliveId = golive.id;
    let taskId = task.id;

    let deletedPosition = 0;
    let founded = false;
    for (let golive of this.golives) {
      if (goliveId == golive.id) {
        for (let task of golive.rollbackTasks) {
          if (taskId == task.id) {
            golive.rollbackTasks[deletedPosition] = golive.rollbackTasks[deletedPosition + 1];
            founded = true;
          }
          deletedPosition++;
        }
        golive.rollbackTasks.length--;
      }
    }


    this.golivesChanged.emit(this.golives);
    let golivee;
    this.http.delete('/api/v1/rollbacktasks/delete/' + taskId , this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => console.log('Rollbacktask was deleted?' + golivee)
    );
  }

  public getGoLives() {
    return this.golives;
  }

  public getGoLive(id: number) {
    for (let golive of this.golives) {
      if (golive.id === id) {
        return golive;
      }
    }
    return null;
  }

  public getGoLivesCount() {
    if (this.golives != undefined) {
      var count = 0;
      for (let golive of this.golives) {
        count++;
      }
      return count;
    }
  }

  public getRunbookTaskCount() {
    var count = 0;
    if (this.golives != undefined) {
      for (let golive of this.golives) {
        for (let runbooktask of golive.runbookTasks) {
          count++;
        }
      }
    }
    return count;
  }

  public getRollbackTaskCount() {
    var count = 0;
    if (this.golives != undefined) {
      for (let golive of this.golives) {
        for (let rollbacktask of golive.rollbackTasks) {
          count++;
        }
      }
    }
    return count;
  }

  public getRunbookTasks() {
    let tasks = [
      new Runbooktask(
        1,
        '',
        '',
        '',
        '',
        new TaskStatus('Dsdf', 'komisch'),
        new User('', '', '', '', '', ''),
        [new User('', '', '', '', '', '')],
        null,
        null,
        null,
        null,
        '',
        0
      )
    ];

    if (this.golives != undefined) {
      for (let golive of this.golives) {
        if (golive.runbookTasks != null) {
          for (let task of golive.runbookTasks) {
            tasks.push(task);
          }
        }
      }
      tasks.splice(0,1);
    }
    this.runbooktasks = tasks;
    this.runbookTasksChanged.emit(tasks);
    return tasks;
  }

  public getRollbackTasks() {
    let tasks = [
      new Rollbacktask(
        1,
        '',
        '',
        '',
        '',
        new TaskStatus('Dsdf', 'komisch'),
        new User('', '', '', '', '', ''),
        [new User('', '', '', '', '', '')],
        null,
        null,
        null,
        null,
        '',
        0
      )
    ];

    if (this.golives != undefined) {
      for (let golive of this.golives) {
        if (golive.rollbackTasks != null) {
          for (let task of golive.rollbackTasks) {
            tasks.push(task);
          }
        }
      }
      tasks.splice(0,1);
    }
    this.rollbacktasks = tasks;
    this.rollbackTasksChanged.emit(tasks);
    return tasks;
  }

  private updateProgresses() {
    if (this.golives != undefined) {
      for (let golive of this.golives) {
        golive.progress = 0;
        let openTasksCount = 0;
        let doneTasksCount = 0;

        if (golive.runbookTasks != 0) {
          for (let tasks of golive.runbookTasks) {
            if (tasks.status.description == 'done') {
              doneTasksCount++;
            } else {
              openTasksCount++;
            }
          }
        }

        if (golive.rollbackTasks != 0) {
          for (let tasks of golive.rollbackTasks) {
            if (tasks.status.description == 'done') {
              doneTasksCount++;
            } else {
              openTasksCount++;
            }
          }
        }

        let allTasksCount = openTasksCount + doneTasksCount;

        if (allTasksCount != 0) {
          golive.progress = Math.round((( doneTasksCount * 100 ) / allTasksCount));

        }
      }
    }
    this.golivesChanged.emit(this.golives);
  }

  public duplicateGoLive(golive: GoLive) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };

    let golivee;
    let count = this.golives.length;
    golive.id = null;
    golive.name = 'COPY of ' + golive.name;
    this.golives[count] = golive;
    this.golivesChanged.emit(this.golives);
    let body = JSON.stringify(golive);

    this.http.put('/api/v1/golives/duplicate' , body, this.httpOptionsWithToken).subscribe(
      data => { golivee = data; } ,
      err => console.error(err),
      () => {


        let string = this.userService.getCurrentUserFirstName() +': Runbook ' + golive.name + ' was duplicated';

        this.changeEvent.emit(string);
      }
    );

  }

}
