import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';

import { AuthService } from './services/security/auth.service';
import { ProtectedGuard } from './services/security/protected.guard';
import { GoliveService } from './services/data2/golive.service';
import { NotificationService } from './services/data2/notification.service';
import { SettingsService } from './services/settings/settings.service';

import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';

import {UserprofileService} from './services/data2/userprofile.service';
import {TeamService} from './services/data2/team.service';
import {LoadInitControllerService} from './services/controller/load-init-controller.service';
import {WebsocketService} from './services/websocket/websocket.service';
import {SnackbarComponent} from './toolbox/dialogs/snackbar/snackbar.component';
import {BookService} from './services/data/book.service';
import {UserService} from './services/data/user.service';
import {LawService} from './services/data/law.service';
import {CourseService} from './services/data/course.service';
import {BookurlService} from './services/data/bookurl.service';
import {ParserService} from './services/data/parser.service';
import {TextHighlighter} from './services/pipes/textHighlighter.pipe';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
    SnackbarComponent,
  ],
  providers: [
    AuthService,
    ProtectedGuard,
    BookService,
    LawService,
    CourseService,
    BookurlService,
    ParserService,
    UserService,
    GoliveService,
    UserprofileService,
    TeamService,
    NotificationService,
    SettingsService,
    WebsocketService,
    LoadInitControllerService,
    SnackbarComponent
  ],
  bootstrap: [AppComponent],
  exports: [
    SnackbarComponent,
  ],
})
export class AppModule {}
