import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {User} from '../../models/team/user/user.model';
import {Team} from '../../models/team/team.model';
import {UserprofileService} from './userprofile.service';
import {SnackbarComponent} from '../../toolbox/dialogs/snackbar/snackbar.component';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  currentTeamChanged = new EventEmitter<any[]>();

  currentTeam;

  httpOptionsWithToken;

  snackBar: SnackbarComponent;

  constructor(
    private http: HttpClient,
    private router: Router,
    private userprofileService: UserprofileService,
    snackBar: SnackbarComponent,
  ) {
    this.snackBar = snackBar;
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': sessionStorage.getItem('bearerToken')})
    };
  }

  // Lädt das Team des aktuellen Nutzers
  public loadTeamOfCurrentUser() {
    if (this.userprofileService.getCurrentUser() != undefined) {
      let currentUserId = this.userprofileService.getCurrentUserId();
      this.http.get('/api/v1/teams/findbyuser/' + currentUserId, this.httpOptionsWithToken ).subscribe(
        data => {
          this.currentTeam = data,
          this.currentTeamChanged.emit(this.currentTeam),
          sessionStorage.setItem('teamid', this.currentTeam.id); this.parse(); } ,
        err => console.error(err),
        () => console.log('Team {} in Service loaded', currentUserId)
      );
    }
  }

  // Updated ein Team (alles außer die Member, die müssen extra gemacht werden
  public updateTeam(team: Team) {
    let body = JSON.stringify(team);

    this.http.put('/api/v1/teams/update' , body, this.httpOptionsWithToken).subscribe(
      data => { this.currentTeam = data, this.parse(); } ,
      err => console.error(err),
      () => console.log('Team was updated')
    );
  }

  // Updated ein Team
  public addMember(user: User) {
    let body = JSON.stringify(user);

    this.http.put('/api/v1/teams/addmember/' + this.currentTeam.id , body, this.httpOptionsWithToken).subscribe(
      data => { this.currentTeam = data, this.parse(); } ,
      err => console.error(err),
      () => console.log('Member was added')
    );
  }

  // Updated ein Team
  public deleteMember(id) {
    this.httpOptionsWithToken = {
      headers: new HttpHeaders({'Authorization': sessionStorage.getItem('bearerToken')})
    };

    this.http.delete('/api/v1/users/deletemember/' + id , this.httpOptionsWithToken).subscribe(
      data => { this.currentTeam = data, this.snackBar.openSnackBar("Member was deleted", "Ok"), this.parse(); } ,
      err => {
        console.error(err);
        this.snackBar.openSnackBar("Member cannot be deleted because the member is involved in Runbooks as a Responsible or an involved Person!", "Ok");
      },
      () => console.log('Member was deleted')
    );
  }

  public getMembers() {
    return this.currentTeam.members;
  }

  // gibt anderen Componenten den aktuellen User
  public getCurrentTeam() {
    return this.currentTeam;
  }

  public getCurrentTeamId() {
    if (this.currentTeam != undefined) {
      return this.currentTeam.id;
    }
  }

  parse() {
    this.currentTeamChanged.emit(this.currentTeam);
  }
}
