import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { UserService } from '../data/user.service';


@Injectable()
export class ProtectedGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const stateToken = this.authService.isUserLoggedIn();
    if (stateToken) {
      return true;
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } , skipLocationChange: true});
    return false;
  }
}
